<template>
  <div class="">
    <page-header
      light
      dark-crumb
      title="Пользовательское соглашение и политика конфиденциальности"
    />
    <content-wrapper-block>
      <div class="agreement">
        <div class="agreement-text">
          <p>1. ОБЩИЕ ПОЛОЖЕНИЯ</p>
          <p>
            1.1 АО «ГК ОСНОВА» (далее — Общество) предлагает Пользователям
            присоединиться к настоящему Пользовательскому соглашению на
            условиях, указанных в данном документе.
          </p>
          <p>
            1.2 Настоящее Пользовательское соглашение определяет условия
            использования Сайта.
          </p>
          <p>
            1.3 Фактом присоединения к Пользовательскому соглашению является
            любое использование Сайта, равно как и нажатие одной из кнопок
            (ссылок), размещенных на Сайте: «Отправить заявку», «Получить
            консультацию», «Заказать звонок», «Отправить», «Забронировать»,
            «Подписаться», «Перезвонить мне», «Зарегистрироваться» или «Войти».
          </p>
          <p>2. ОСНОВНЫЕ ТЕРМИНЫ И ОПРЕДЕЛЕНИЯ</p>
          <p>
            Персональные данные - любая информация, относящаяся к прямо или
            косвенно определенному или определяемому физическому лицу (субъекту
            персональных данных).
          </p>
          <p>
            Общество - АО «ГК ОСНОВА», зарегистрированный по адресу: 142702,
            Московская обл., город Видное, поселок городского типа Бутово,
            территория жилой комплекс Бутово-Парк, д. 18, пом. 59.
          </p>
          <p>
            Пользователь – физическое лицо, намеревающееся принять
            Пользовательское соглашение или принявшее его, а также использующее
            Сайт посредством информационно-телекоммуникационной сети «Интернет».
          </p>
          <p>
            Сайт – это совокупность текстов, графических элементов, дизайна,
            изображений, программного кода, и иных результатов интеллектуальной
            деятельности, содержащихся в информационно -телекоммуникационной
            сети «Интернет» под доменным именем «zk-mainstreet.ru», а также его
            поддоменов.
          </p>
          <p>3. УСЛОВИЯ ИСПОЛЬЗОВАНИЯ САЙТА</p>
          <p>
            3.1 Использование Сайта означает, что Пользователь ознакомился и
            принимает Пользовательское соглашение, изложенное на данной
            странице. Если Пользователь не ознакомились и (или) не принимаете
            данные условия, то Пользователь обязан прекратить использование
            Сайта.
          </p>
          <p>
            3.2 Общество оставляет за собой право в любое время изменять условия
            Пользовательского соглашения и Политику обработки персональных
            данных и рекомендует Пользователю регулярно просматривать их, чтобы
            знать обо всех изменениях. Продолжение использования Сайта после
            внесения Обществом изменений будет означать согласие Пользователя с
            измененными условиями.
          </p>
          <p>
            3.3 Сайт носит исключительно информационный характер и никакая
            информация, опубликованная на нём, ни при каких условиях не является
            публичной офертой.
          </p>
          <p>
            3.4 Пользователь вправе в свободной форме и по своему усмотрению
            знакомиться с содержимым Сайта.
          </p>
          <p>
            3.5 Пользователь подтверждает, что он является дееспособным и достиг
            возраста, необходимого в соответствии с законодательством Российской
            Федерации для совершения сделок, предусмотренных Пользовательским
            соглашением.
          </p>
          <p>
            3.6 Для правомерного использования (копирование, тиражирование)
            контента необходимо согласие владельца Сайта (Общества) или
            правообладателя материалов.
          </p>
          <p>
            3.7 Использование общедоступных сведений или иной информации, доступ
            к которой не ограничен, разрешено при соблюдении установленных
            федеральными законами ограничений в отношении распространения такой
            информации.
          </p>
          <p>
            3.8 Запрещены любые несанкционированные и (или) неправомерные
            действия в отношении Сайта, в том числе любые действия, направленные
            на нарушение работы Сайта или его компонентов.
          </p>
          <p>4. ОБРАБОТКА ПЕРСОНАЛЬНЫХ ДАННЫХ</p>
          <p>
            4.1 Пользователь, используя Сайт и (или) предоставляя в любой форме
            (заполнение обратной формы, отправка письма на контактный e-mail
            адрес, использование контактного телефонного номера, передача
            электронных данных, cookies) свои персональные данные, выражает
            согласие на обработку персональных данных в соответствии с
            Федеральным законом «О персональных данных» от 27.07.2006 № 152-ФЗ.
            Обработка персональных данных Пользователя регулируется действующим
            законодательством Российской Федерации и Политикой обработки
            персональных данных, размещенной на Сайте.
          </p>
          <p>
            4.2 Пользователь гарантирует достоверность персональной информации,
            предоставленной при принятии Пользовательского соглашения, принимает
            на себя всю ответственность за ее точность, полноту, а также
            поддерживает эту информацию в актуальном состоянии. Пользователь
            принимает на себя все возможные риски, связанные с его действиями,
            совершенными с ошибками или неточностями в предоставленной
            персональной информации.
          </p>
          <p>4.3 Перечень персональных данных, подлежащих обработке:</p>
          <p>• Персональная информация (фамилия, имя, отчество).</p>
          <p>
            • Контактные данные (номер телефона, адрес электронной почты,
            псевдоним (идентификатор) в социальных сетях и сервисах
            коммуникаций).
          </p>
          <p>
            • Электронные пользовательские данные (идентификаторы пользователя,
            сетевые адреса (IP), файлы cookies, идентификаторы устройств,
            размеры и разрешение экрана, сведения об аппаратном и программном
            обеспечении Пользователя (например, используемый браузер,
            операционная система, геолокация, языковые настройки, часовой пояс,
            время, статистика использования Сайта, действия Пользователя на
            Сайте, источники переходов на Сайт, отправленные поисковые и иные
            запросы).
          </p>
          <p>
            4.4 Персональные данные Пользователей обрабатываются для следующих
            целей:
          </p>
          <p>• идентификации Пользователя, создания учетной записи;</p>
          <p>
            • установления с Пользователем обратной связи, включая направление
            уведомлений, запросов, касающихся использования Сайта, оказания
            услуг, обработку запросов и заявок от Пользователя и направление по
            ним ответов;
          </p>
          <p>
            • исполнение договорных обязательств Общества перед Пользователем;
          </p>
          <p>
            • оказание информационных, справочных и консультационных услуг в
            рамках телефонного обслуживания;
          </p>
          <p>
            • направление по электронной почте или телефону сообщений рекламного
            и информационного характера, содержащих информацию об услугах,
            товарах и работах Общества;
          </p>
          <p>• повышение эффективности и удобства работы с Сайтом;</p>
          <p>
            • проведение статистических и иных исследований на основе
            обезличенных данных.
          </p>
          <p>
            4.5 В ходе обработки персональных данных будут совершены следующие
            действия: сбор, запись, систематизация, накопление, хранение,
            уточнение (обновление, изменение), извлечение, использование,
            передача (предоставление, доступ), блокирование, удаление,
            уничтожение.
          </p>
          <p>
            4.6 Пользователь имеет право отозвать свое согласие на обработку
            персональных данных путем отправки письменного запроса в адрес
            Общества, в том числе посредством электронной почты
            info@gk-osnova.ru.
          </p>
          <p>
            4.7 Если Пользователь считает, что Общество осуществляет обработку
            его персональных данных с нарушением требований Федерального закона
            от 27.07.2006 №152-ФЗ «О персональных данных» или иным образом
            нарушает его права и свободы, Пользователь вправе обжаловать
            действия или бездействие Общества в Уполномоченный орган по защите
            прав субъектов персональных данных или в судебном порядке.
          </p>
          <p>
            5. ОБРАБОТКА ЭЛЕКТРОННЫХ ПОЛЬЗОВАТЕЛЬСКИХ ДАННЫХ, ВКЛЮЧАЯ COOKIES
          </p>
          <p>
            5.1 Файлы cookies — это небольшой фрагмент данных, который Сайт
            запрашивает у браузера, используемого на устройстве Пользователя.
            Файлы cookies содержат информацию о действиях Пользователя на Сайте,
            а также могут содержать сведения об оборудовании Пользователя, дату
            и время сессии.
          </p>
          <p>
            5.2 Пользователю Сайта могут показываться всплывающие уведомления о
            сборе и обработке данных cookies со ссылкой на данное
            Пользовательское соглашение и Политику обработки персональных
            данных.
          </p>
          <p>
            5.3 Если Пользователь не согласен с обработкой данных cookies, он
            может самостоятельно произвести настройку браузера запрещающую
            принимать и отправлять данные cookies для Сайта и (или) иным
            способом блокировать обработку данных cookies, или незамедлительно
            покинуть Сайт. Пользователь может самостоятельно управлять
            (просматривать, удалять) уже сохраненными данными cookies в
            браузере. При этом при блокировании или удалении файлов cookies
            возможна некорректная работа нашего сайта в Вашем браузере.
          </p>
          <p>
            5.4 Файлы cookies используются Обществом в целях улучшения и
            обеспечения работоспособности Сайта.
          </p>
          <p>5.5 Виды используемых файлов cookies:</p>
          <p>
            • Сессионные. Существуют только во временной памяти в течение
            времени, когда Пользователь находится на странице Сайта. Браузеры
            обычно удаляют сессионные файлы cookies после того, как Пользователь
            закрывает окно Сайта. Сессионные файлы cookies позволяют Сайту
            помнить информацию о выборе Пользователя на предыдущей странице
            Сайта, чтобы избежать необходимости повторного ввода информации.
          </p>
          <p>
            • Постоянные. Файлы cookies, которые хранятся на устройстве
            Пользователя и не удаляются при закрытии браузера. Такие файлы
            cookies позволяют идентифицировать Пользователя как уникального
            пользователя Сайта, и при возвращении на Сайт помогают вспомнить
            информацию о Пользователе и ранее совершенных им действиях.
          </p>
          <p>
            • Обязательные. Минимальный набор файлов cookies, использование
            которых необходимо для корректной работы Cайта.
          </p>
          <p>
            • Аналитические / маркетинговые. Включают в себя информацию о том,
            как Пользователь используете Сайт или насколько эффективны
            маркетинговые кампании Общества. Главная цель таких файлов cookies —
            улучшение функций и работы Сайта. Позволяют показывать Пользователям
            Сайта релевантные предложения для улучшения пользовательского опыта.
            На Сайте происходит сбор и обработка обезличенных данных с помощью
            сервиса интернет-статистики: «Яндекс.Метрика». Эта услуга
            предоставляется компанией ООО «ЯНДЕКС» (ИНН 7736207543).
            Дополнительную информацию о сервисе «Яндекс.Метрика» и политике
            конфиденциальности ООО «ЯНДЕКС» можно найти по адресу:
            https://yandex.ru/legal/confidential/ и
            https://yandex.ru/legal/metrica_termsofuse/.
          </p>
          <p>
            5.6 В браузере на устройстве Пользователя может сохраняться
            информация (cookies), относящаяся к сайтам сторонних организаций,
            например, при использовании сторонних модулей, компонентов или иного
            программного обеспечения. Обработка такой информации регулируется
            политиками соответствующих сайтов, к которым она относится. Политика
            сторонних организаций может изменяться без уведомления Пользователя
            Сайта.
          </p>
          <p>
            5.7 Примеры сторонних модулей, компонентов или программного
            обеспечения:
          </p>
          <p>
            • сервисы защиты от атак, способных заблокировать и (или) нарушить
            работу Сайта (DoS, DDoS, сканирование и поиск уязвимостей, попытки
            эксплуатации уязвимостей);
          </p>
          <p>• сервисы и виджеты защиты от спама, интернет-ботов;</p>
          <p>
            • счетчики посещений, аналитических и статистических сервисов, таких
            как «Яндекс.Метрика» для сбора статистики посещаемости общедоступных
            страниц Сайта;
          </p>
          <p>
            • виджеты вспомогательных сервисов для предоставления обратной
            связи, организации чатов и иных видов коммуникаций с Пользователем;
          </p>
          <p>• сервисы и модули оптимизации производительности работы Сайта;</p>
          <p>
            • Сайт может использовать почтовый сервис Mail.ru. Эта услуга
            предоставляется компанией ООО «ВК» (ИНН 7743001840). Почта Mail.ru
            может использоваться для отправки и получения электронных сообщений
            через форму обратной связи на Сайте. Дополнительную информацию о
            почтовом сервисе Mail.ru и политике конфиденциальности ООО «ВК»
            можно найти по адресу:
            <span class="breakable-link"
              >https://help.mail.ru/legal/terms/common/privacy.</span
            >
          </p>
          <p>6. ЗАКЛЮЧИТЕЛЬНЫЕ ПОЛОЖЕНИЯ</p>
          <p>
            6.1 В данное Пользовательское соглашения могут вноситься изменения и
            дополнения. Новая редакция Пользовательского соглашения вступает в
            силу с момента ее размещения на Сайте Общества. Пользователь обязан
            своевременно знакомится с действующим Пользовательским соглашением.
          </p>
          <p>
            6.2 Действующая версия Пользовательского соглашения размещена в
            открытом доступе на Сайте: https://zk-mainstreet.ru/.
          </p>
        </div>
      </div>
    </content-wrapper-block>
  </div>
</template>

<script>
import ContentWrapperBlock from "@/components/blocks/ContentWrapperBlock";
import PageHeader from "@/components/elements/PageHeader";

export default {
  name: "UserAgreementPage",
  components: {
    PageHeader,
    ContentWrapperBlock,
  },
};
</script>

<style lang="scss" scoped>
.agreement-text {
  font-family: $font-family-rubik;
  font-size: 13px;
  p {
    font-family: Rubik;
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 170%;
    letter-spacing: 0.03em;
    color: #18181b;
    margin: 16px 0;
  }
  .breakable-link {
    word-break: break-all;
  }
}
.page-header {
  padding-bottom: 0;
}
</style>
