var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {},
    [
      _c("page-header", {
        attrs: {
          light: "",
          "dark-crumb": "",
          title: "Пользовательское соглашение и политика конфиденциальности",
        },
      }),
      _c("content-wrapper-block", [
        _c("div", { staticClass: "agreement" }, [
          _c("div", { staticClass: "agreement-text" }, [
            _c("p", [_vm._v("1. ОБЩИЕ ПОЛОЖЕНИЯ")]),
            _c("p", [
              _vm._v(
                " 1.1 АО «ГК ОСНОВА» (далее — Общество) предлагает Пользователям присоединиться к настоящему Пользовательскому соглашению на условиях, указанных в данном документе. "
              ),
            ]),
            _c("p", [
              _vm._v(
                " 1.2 Настоящее Пользовательское соглашение определяет условия использования Сайта. "
              ),
            ]),
            _c("p", [
              _vm._v(
                " 1.3 Фактом присоединения к Пользовательскому соглашению является любое использование Сайта, равно как и нажатие одной из кнопок (ссылок), размещенных на Сайте: «Отправить заявку», «Получить консультацию», «Заказать звонок», «Отправить», «Забронировать», «Подписаться», «Перезвонить мне», «Зарегистрироваться» или «Войти». "
              ),
            ]),
            _c("p", [_vm._v("2. ОСНОВНЫЕ ТЕРМИНЫ И ОПРЕДЕЛЕНИЯ")]),
            _c("p", [
              _vm._v(
                " Персональные данные - любая информация, относящаяся к прямо или косвенно определенному или определяемому физическому лицу (субъекту персональных данных). "
              ),
            ]),
            _c("p", [
              _vm._v(
                " Общество - АО «ГК ОСНОВА», зарегистрированный по адресу: 142702, Московская обл., город Видное, поселок городского типа Бутово, территория жилой комплекс Бутово-Парк, д. 18, пом. 59. "
              ),
            ]),
            _c("p", [
              _vm._v(
                " Пользователь – физическое лицо, намеревающееся принять Пользовательское соглашение или принявшее его, а также использующее Сайт посредством информационно-телекоммуникационной сети «Интернет». "
              ),
            ]),
            _c("p", [
              _vm._v(
                " Сайт – это совокупность текстов, графических элементов, дизайна, изображений, программного кода, и иных результатов интеллектуальной деятельности, содержащихся в информационно -телекоммуникационной сети «Интернет» под доменным именем «zk-mainstreet.ru», а также его поддоменов. "
              ),
            ]),
            _c("p", [_vm._v("3. УСЛОВИЯ ИСПОЛЬЗОВАНИЯ САЙТА")]),
            _c("p", [
              _vm._v(
                " 3.1 Использование Сайта означает, что Пользователь ознакомился и принимает Пользовательское соглашение, изложенное на данной странице. Если Пользователь не ознакомились и (или) не принимаете данные условия, то Пользователь обязан прекратить использование Сайта. "
              ),
            ]),
            _c("p", [
              _vm._v(
                " 3.2 Общество оставляет за собой право в любое время изменять условия Пользовательского соглашения и Политику обработки персональных данных и рекомендует Пользователю регулярно просматривать их, чтобы знать обо всех изменениях. Продолжение использования Сайта после внесения Обществом изменений будет означать согласие Пользователя с измененными условиями. "
              ),
            ]),
            _c("p", [
              _vm._v(
                " 3.3 Сайт носит исключительно информационный характер и никакая информация, опубликованная на нём, ни при каких условиях не является публичной офертой. "
              ),
            ]),
            _c("p", [
              _vm._v(
                " 3.4 Пользователь вправе в свободной форме и по своему усмотрению знакомиться с содержимым Сайта. "
              ),
            ]),
            _c("p", [
              _vm._v(
                " 3.5 Пользователь подтверждает, что он является дееспособным и достиг возраста, необходимого в соответствии с законодательством Российской Федерации для совершения сделок, предусмотренных Пользовательским соглашением. "
              ),
            ]),
            _c("p", [
              _vm._v(
                " 3.6 Для правомерного использования (копирование, тиражирование) контента необходимо согласие владельца Сайта (Общества) или правообладателя материалов. "
              ),
            ]),
            _c("p", [
              _vm._v(
                " 3.7 Использование общедоступных сведений или иной информации, доступ к которой не ограничен, разрешено при соблюдении установленных федеральными законами ограничений в отношении распространения такой информации. "
              ),
            ]),
            _c("p", [
              _vm._v(
                " 3.8 Запрещены любые несанкционированные и (или) неправомерные действия в отношении Сайта, в том числе любые действия, направленные на нарушение работы Сайта или его компонентов. "
              ),
            ]),
            _c("p", [_vm._v("4. ОБРАБОТКА ПЕРСОНАЛЬНЫХ ДАННЫХ")]),
            _c("p", [
              _vm._v(
                " 4.1 Пользователь, используя Сайт и (или) предоставляя в любой форме (заполнение обратной формы, отправка письма на контактный e-mail адрес, использование контактного телефонного номера, передача электронных данных, cookies) свои персональные данные, выражает согласие на обработку персональных данных в соответствии с Федеральным законом «О персональных данных» от 27.07.2006 № 152-ФЗ. Обработка персональных данных Пользователя регулируется действующим законодательством Российской Федерации и Политикой обработки персональных данных, размещенной на Сайте. "
              ),
            ]),
            _c("p", [
              _vm._v(
                " 4.2 Пользователь гарантирует достоверность персональной информации, предоставленной при принятии Пользовательского соглашения, принимает на себя всю ответственность за ее точность, полноту, а также поддерживает эту информацию в актуальном состоянии. Пользователь принимает на себя все возможные риски, связанные с его действиями, совершенными с ошибками или неточностями в предоставленной персональной информации. "
              ),
            ]),
            _c("p", [
              _vm._v("4.3 Перечень персональных данных, подлежащих обработке:"),
            ]),
            _c("p", [
              _vm._v("• Персональная информация (фамилия, имя, отчество)."),
            ]),
            _c("p", [
              _vm._v(
                " • Контактные данные (номер телефона, адрес электронной почты, псевдоним (идентификатор) в социальных сетях и сервисах коммуникаций). "
              ),
            ]),
            _c("p", [
              _vm._v(
                " • Электронные пользовательские данные (идентификаторы пользователя, сетевые адреса (IP), файлы cookies, идентификаторы устройств, размеры и разрешение экрана, сведения об аппаратном и программном обеспечении Пользователя (например, используемый браузер, операционная система, геолокация, языковые настройки, часовой пояс, время, статистика использования Сайта, действия Пользователя на Сайте, источники переходов на Сайт, отправленные поисковые и иные запросы). "
              ),
            ]),
            _c("p", [
              _vm._v(
                " 4.4 Персональные данные Пользователей обрабатываются для следующих целей: "
              ),
            ]),
            _c("p", [
              _vm._v("• идентификации Пользователя, создания учетной записи;"),
            ]),
            _c("p", [
              _vm._v(
                " • установления с Пользователем обратной связи, включая направление уведомлений, запросов, касающихся использования Сайта, оказания услуг, обработку запросов и заявок от Пользователя и направление по ним ответов; "
              ),
            ]),
            _c("p", [
              _vm._v(
                " • исполнение договорных обязательств Общества перед Пользователем; "
              ),
            ]),
            _c("p", [
              _vm._v(
                " • оказание информационных, справочных и консультационных услуг в рамках телефонного обслуживания; "
              ),
            ]),
            _c("p", [
              _vm._v(
                " • направление по электронной почте или телефону сообщений рекламного и информационного характера, содержащих информацию об услугах, товарах и работах Общества; "
              ),
            ]),
            _c("p", [
              _vm._v("• повышение эффективности и удобства работы с Сайтом;"),
            ]),
            _c("p", [
              _vm._v(
                " • проведение статистических и иных исследований на основе обезличенных данных. "
              ),
            ]),
            _c("p", [
              _vm._v(
                " 4.5 В ходе обработки персональных данных будут совершены следующие действия: сбор, запись, систематизация, накопление, хранение, уточнение (обновление, изменение), извлечение, использование, передача (предоставление, доступ), блокирование, удаление, уничтожение. "
              ),
            ]),
            _c("p", [
              _vm._v(
                " 4.6 Пользователь имеет право отозвать свое согласие на обработку персональных данных путем отправки письменного запроса в адрес Общества, в том числе посредством электронной почты info@gk-osnova.ru. "
              ),
            ]),
            _c("p", [
              _vm._v(
                " 4.7 Если Пользователь считает, что Общество осуществляет обработку его персональных данных с нарушением требований Федерального закона от 27.07.2006 №152-ФЗ «О персональных данных» или иным образом нарушает его права и свободы, Пользователь вправе обжаловать действия или бездействие Общества в Уполномоченный орган по защите прав субъектов персональных данных или в судебном порядке. "
              ),
            ]),
            _c("p", [
              _vm._v(
                " 5. ОБРАБОТКА ЭЛЕКТРОННЫХ ПОЛЬЗОВАТЕЛЬСКИХ ДАННЫХ, ВКЛЮЧАЯ COOKIES "
              ),
            ]),
            _c("p", [
              _vm._v(
                " 5.1 Файлы cookies — это небольшой фрагмент данных, который Сайт запрашивает у браузера, используемого на устройстве Пользователя. Файлы cookies содержат информацию о действиях Пользователя на Сайте, а также могут содержать сведения об оборудовании Пользователя, дату и время сессии. "
              ),
            ]),
            _c("p", [
              _vm._v(
                " 5.2 Пользователю Сайта могут показываться всплывающие уведомления о сборе и обработке данных cookies со ссылкой на данное Пользовательское соглашение и Политику обработки персональных данных. "
              ),
            ]),
            _c("p", [
              _vm._v(
                " 5.3 Если Пользователь не согласен с обработкой данных cookies, он может самостоятельно произвести настройку браузера запрещающую принимать и отправлять данные cookies для Сайта и (или) иным способом блокировать обработку данных cookies, или незамедлительно покинуть Сайт. Пользователь может самостоятельно управлять (просматривать, удалять) уже сохраненными данными cookies в браузере. При этом при блокировании или удалении файлов cookies возможна некорректная работа нашего сайта в Вашем браузере. "
              ),
            ]),
            _c("p", [
              _vm._v(
                " 5.4 Файлы cookies используются Обществом в целях улучшения и обеспечения работоспособности Сайта. "
              ),
            ]),
            _c("p", [_vm._v("5.5 Виды используемых файлов cookies:")]),
            _c("p", [
              _vm._v(
                " • Сессионные. Существуют только во временной памяти в течение времени, когда Пользователь находится на странице Сайта. Браузеры обычно удаляют сессионные файлы cookies после того, как Пользователь закрывает окно Сайта. Сессионные файлы cookies позволяют Сайту помнить информацию о выборе Пользователя на предыдущей странице Сайта, чтобы избежать необходимости повторного ввода информации. "
              ),
            ]),
            _c("p", [
              _vm._v(
                " • Постоянные. Файлы cookies, которые хранятся на устройстве Пользователя и не удаляются при закрытии браузера. Такие файлы cookies позволяют идентифицировать Пользователя как уникального пользователя Сайта, и при возвращении на Сайт помогают вспомнить информацию о Пользователе и ранее совершенных им действиях. "
              ),
            ]),
            _c("p", [
              _vm._v(
                " • Обязательные. Минимальный набор файлов cookies, использование которых необходимо для корректной работы Cайта. "
              ),
            ]),
            _c("p", [
              _vm._v(
                " • Аналитические / маркетинговые. Включают в себя информацию о том, как Пользователь используете Сайт или насколько эффективны маркетинговые кампании Общества. Главная цель таких файлов cookies — улучшение функций и работы Сайта. Позволяют показывать Пользователям Сайта релевантные предложения для улучшения пользовательского опыта. На Сайте происходит сбор и обработка обезличенных данных с помощью сервиса интернет-статистики: «Яндекс.Метрика». Эта услуга предоставляется компанией ООО «ЯНДЕКС» (ИНН 7736207543). Дополнительную информацию о сервисе «Яндекс.Метрика» и политике конфиденциальности ООО «ЯНДЕКС» можно найти по адресу: https://yandex.ru/legal/confidential/ и https://yandex.ru/legal/metrica_termsofuse/. "
              ),
            ]),
            _c("p", [
              _vm._v(
                " 5.6 В браузере на устройстве Пользователя может сохраняться информация (cookies), относящаяся к сайтам сторонних организаций, например, при использовании сторонних модулей, компонентов или иного программного обеспечения. Обработка такой информации регулируется политиками соответствующих сайтов, к которым она относится. Политика сторонних организаций может изменяться без уведомления Пользователя Сайта. "
              ),
            ]),
            _c("p", [
              _vm._v(
                " 5.7 Примеры сторонних модулей, компонентов или программного обеспечения: "
              ),
            ]),
            _c("p", [
              _vm._v(
                " • сервисы защиты от атак, способных заблокировать и (или) нарушить работу Сайта (DoS, DDoS, сканирование и поиск уязвимостей, попытки эксплуатации уязвимостей); "
              ),
            ]),
            _c("p", [
              _vm._v("• сервисы и виджеты защиты от спама, интернет-ботов;"),
            ]),
            _c("p", [
              _vm._v(
                " • счетчики посещений, аналитических и статистических сервисов, таких как «Яндекс.Метрика» для сбора статистики посещаемости общедоступных страниц Сайта; "
              ),
            ]),
            _c("p", [
              _vm._v(
                " • виджеты вспомогательных сервисов для предоставления обратной связи, организации чатов и иных видов коммуникаций с Пользователем; "
              ),
            ]),
            _c("p", [
              _vm._v(
                "• сервисы и модули оптимизации производительности работы Сайта;"
              ),
            ]),
            _c("p", [
              _vm._v(
                " • Сайт может использовать почтовый сервис Mail.ru. Эта услуга предоставляется компанией ООО «ВК» (ИНН 7743001840). Почта Mail.ru может использоваться для отправки и получения электронных сообщений через форму обратной связи на Сайте. Дополнительную информацию о почтовом сервисе Mail.ru и политике конфиденциальности ООО «ВК» можно найти по адресу: "
              ),
              _c("span", { staticClass: "breakable-link" }, [
                _vm._v("https://help.mail.ru/legal/terms/common/privacy."),
              ]),
            ]),
            _c("p", [_vm._v("6. ЗАКЛЮЧИТЕЛЬНЫЕ ПОЛОЖЕНИЯ")]),
            _c("p", [
              _vm._v(
                " 6.1 В данное Пользовательское соглашения могут вноситься изменения и дополнения. Новая редакция Пользовательского соглашения вступает в силу с момента ее размещения на Сайте Общества. Пользователь обязан своевременно знакомится с действующим Пользовательским соглашением. "
              ),
            ]),
            _c("p", [
              _vm._v(
                " 6.2 Действующая версия Пользовательского соглашения размещена в открытом доступе на Сайте: https://zk-mainstreet.ru/. "
              ),
            ]),
          ]),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }